<template>
  <div class="wechat-container">
    <div class="header">
      <div class="header-left">
        <img :src="logoSrc" alt="Logo" class="logo" />
        <div class="brand-container">
          <span class="brand-name">{{ brandName }}</span>
          <span class="history-record">历史记录</span>
        </div>
      </div>
      <div class="header-right">
        <img src="@/assets/images/logout.png" alt="Home" class="home-icon" @click="goToHome" />
      </div>
    </div>
    <div class="chat-list">
      <div class="chat-list-content">
        <div 
          class="chat-item" 
          v-for="chat in chats" 
          :key="chat.chatId" 
          @click="selectChat(chat.chatId)"
          :class="{ 'selected': selectedChatId === chat.chatId }"
        >
          <img :src="logoSrc" alt="" class="avatar"/>
          <div class="chat-info">
            <div class="chat-header">
              <span class="chat-message">{{ chat.message }}</span>
              <span class="chat-time">{{ chat.time }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'; // 导入 Axios 实例

export default {
  name: 'ChatListView',
  data() {
    return {
      chats: [],
      selectedChatId: null, // 用于存储当前选择的聊天ID
      logoSrc: '',
      brandName: '',
    };
  },
  mounted() {
    this.setBranding();
    this.fetchChatList();
  },
  methods: {
    async fetchChatList() {
      try {
        const userId = localStorage.getItem('userId');
        const botId = localStorage.getItem('botId');
        const response = await axios.post(`/session/metadata/getChatMetadata?userId=${encodeURIComponent(userId)}&botId=${encodeURIComponent(botId)}`);
        if (response.data) {
          const now = new Date();
          this.chats = response.data.map(chat => {
            const messageDate = new Date(chat.createTime);
            let displayTime;

            const isToday = messageDate.toDateString() === now.toDateString();
            const isYesterday = new Date(now - 24 * 60 * 60 * 1000).toDateString() === messageDate.toDateString();

            if (isToday) {
              displayTime = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            } else if (isYesterday) {
              displayTime = '昨天';
            } else {
              const diffDays = Math.floor((now - messageDate) / (1000 * 60 * 60 * 24));
              displayTime = `${diffDays}天前`;
            }

            return {
              chatId: chat.chat_id, // 假设每个聊天都有唯一的 id
              message: chat.introduction, // 显示的消息内容
              time: displayTime, // 格式化后的时间
            };
          });
        }
      } catch (error) {
        console.error('Error fetching chat list:', error);
      }
    },
    setBranding() {
      const logoUrl = localStorage.getItem('logoUrl');
      this.logoSrc = logoUrl;
      this.brandName = localStorage.getItem('brandName');
    },
    selectChat(chatId) {
      this.selectedChatId = chatId;
      this.goToChatDetail(chatId);
    },
    goToChatDetail(chatId) {
      localStorage.setItem('chatId',chatId)
      this.$router.push('/detail');
    },
    goToHome() {
      localStorage.setItem('nameVerification','true');
      this.$router.push({ 
        path: '/detail', 
        query: { 
          chatId: localStorage.getItem('chatId') 
        } 
      });
    }
  }
};
</script>

<style scoped>
.wechat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* 确保主容器不会有额外的滚动 */
}

.header {
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.brand-container {
  display: flex;
  flex-direction: column;
}

.brand-name {
  font-size: 16px;
  color: #333;
}

.history-record {
  font-size: 15px;
  color: #888;
}

.header-right {
  display: flex;
  align-items: center;
}

.home-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.chat-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* 隐藏x轴的滚动并确保子元素有空间滚动 */
}

.chat-list-content {
  flex: 1;
  overflow-y: auto; /* 启用垂直滚动 */
  padding-right: 10px;
  box-sizing: border-box;
  scroll-behavior: smooth; /* 添加平滑滚动效果 */
}

.chat-item {
  display: flex;
  padding: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  position: relative;
}

.chat-item:hover {
  background-color: #f0f0f0;
}

.chat-item.selected {
  background-color: #d0d0d0;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-info {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.chat-message {
  color: #666;
  margin-right: 10px;
  flex: 1;
}

.chat-time {
  color: #888;
  white-space: nowrap;
}
</style>
