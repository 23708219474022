import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import './assets/styles/global.css'; // 引入body全局样式
import * as Icons from '@element-plus/icons-vue';

const app = createApp(App);

app.use(router);
app.use(ElementPlus);

// 注册所有图标组件
for (const [key, component] of Object.entries(Icons)) {
    app.component(key, component);
  }

app.mount('#app');
