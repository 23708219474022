<template>
  <div class="login-container">
    <div class="login-content">
      <img src="@/assets/images/xdlogo.jpg" alt="Logo" class="logo">
      <div class="brand-name">晓谛健康</div>
      <form @submit.prevent="login"> <!-- 恢复表单提交功能 -->
        <div class="input-container">
          <input v-model="accountNumber" type="text" placeholder="账号" required>
        </div>
        <div class="input-container">
          <input v-model="password" type="password" placeholder="密码" required>
        </div>
        <button type="submit" class="login-button">登录</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'; // 导入 Axios 实例

export default {
  name: 'XiaodiLoginView',
  data() {
    return {
      accountNumber: '', // 使用 accountNumber 替代 username
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('/api/login', {
          accountNumber: this.accountNumber, // 使用 accountNumber 作为请求参数
          password: this.password
        });
        if (response.data.id) { // 假设返回用户信息中有 id 字段
          localStorage.setItem('loginSource', 'xiaodilogin'); // 在 login1 页面登录后保存来源信息
          localStorage.setItem('isAuthenticated', 'true'); // 保存登录状态
          localStorage.setItem('logoUrl',require('@/assets/images/xdlogo.jpg'));
          localStorage.setItem('brandName','晓谛健康·AIBOSSOS');
          localStorage.setItem('userId', response.data.id); // 保存登录状态
          localStorage.setItem('botId', '614020309325975556'); // 保存登录状态
          localStorage.setItem('botType', 'xiaodiBot'); // 保存登录状态
          localStorage.setItem('honorific','你好，我是晓谛，您的健康建议，可以找晓谛，我们的林鹏博士将不停的训练晓谛，帮助您解答健康知识，管理健康数据，提供健康建议。')
          localStorage.setItem('chatId','');
          localStorage.setItem('nameVerification','true');
          //测试app-vmytgUrcd0Q1WdwBQdJfc3Bg
          //正式app-7TlvFwPDkXBv83opNuK2iHTI
          localStorage.setItem('apiKey','Bearer app-7TlvFwPDkXBv83opNuK2iHTI');
          localStorage.setItem('topToke','晓谛：');
          localStorage.setItem('btmToke','免责声明：如果您给我更多您的健康信息，我将给您更加有针对性的健康建议，同时，本晓谛AI的训练是为了帮您理清思路，并不能替代医生诊断，具体治疗及预防措施，必须听从医生指导，如需要人工服务，请添加林鹏教授的微信 18589056780.');
          this.$router.push('/detail');
        } else {
          alert('登录失败');
        }
      } catch (error) {
        console.error('登录错误:', error);
        alert('登录错误');
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.login-content {
  background-color: transparent;
  padding: 0;
  text-align: center;
}

.logo {
  height: 80px;
  margin-bottom: 10px;
}

.brand-name {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
}

.input-container {
  margin-bottom: 10px;
}

.input-container input {
  width: 300px;
  height: 40px;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加内阴影 */
}

button[type="submit"] {
  width: 314px;
  height: 50px;
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 20px;
  margin-top: 50px;
  cursor: pointer;
}

/* 新的登录按钮样式 */
.login-button {
  width: 300px;
  height: 50px;
  background-color: #f5f5f5;
  border: none;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  margin-top: 20px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.login-button:hover {
  background-color: #e5e5e5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
</style>
