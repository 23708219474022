<template>
  <div class="chat-detail-container">
    <header class="header">
      <div class="header-left">
        <img :src="logoSrc" alt="Logo" class="logo" @click="toggleLogoutConfirm" />
        <span class="brand-name">{{ brandName }}</span>
      </div>
      <div class="header-right">
        <img src="@/assets/images/chat.png" alt="Home" class="home-icon" @click="newChat" />
        <div class="divider"></div>
        <img src="@/assets/images/history.png" alt="Settings" class="settings-icon" @click="goHistory" />
      </div>
    </header>

    <!-- 退出确认弹窗 -->
    <div v-if="showLogoutConfirm" class="logout-confirm">
      <div class="confirm-box">
        <p>是否退出登录?</p>
        <div class="confirm-buttons">
          <button @click="logout">确认</button>
          <button @click="toggleLogoutConfirm">取消</button>
        </div>
      </div>
    </div>

    <!-- 消息列表 -->
    <div class="messages-list">
      <div v-for="message in messages" :key="message.id" :class="['message', message.type]">
        <img v-if="!message.loading && message.avatar" :src="message.avatar" alt="" class="avatar"/>
        <div class="message-content">
          <div class="message-bubble">
            <span class="message-text" v-if="!message.loading" v-html="formatContent(message.content)"></span>
            <div class="loading-dots" v-else>
              <span></span><span></span><span></span>
            </div>
          </div>
          <span class="message-time">{{ message.time }}</span>
        </div>
      </div>
      <div ref="scrollAnchor"></div>
    </div>

     <!-- 文件上传显示区域 -->
    <div v-if="uploadingFiles.length || uploadedFiles.length" class="file-upload-display">
      <div class="uploaded-files-container">
        <!-- 正在上传的文件 -->
        <div
          v-for="file in uploadingFiles"
          :key="file.name"
          class="uploaded-file-progress uploading"
        >
          <img :src="getFileIcon(file.name)" alt="File Icon" class="file-icon"/>
          <div class="file-name">{{ file.name }}</div>

          <!-- 进度条显示 -->
          <div class="circular-progress">
            <svg class="progress-ring" width="48" height="48">
              <circle
                class="progress-ring__circle"
                cx="24"
                cy="24"
                r="22"
                :style="{ strokeDashoffset: getStrokeDashoffset(uploadProgress[file.name]) }"
              ></circle>
            </svg>
            <span class="progress-text">{{ uploadProgress[file.name] }}%</span>
          </div>

          <!-- 上传中显示遮罩 -->
          <div class="overlay" v-if="uploadProgress[file.name] < 100"></div>
        </div>

        <!-- 上传完成的文件 -->
        <div
          v-for="(file, index) in uploadedFiles"
          :key="file.name"
          class="uploaded-file"
        >
          <img :src="getFileIcon(file.name)" alt="File Icon" class="file-icon"/>
          <span class="file-name">{{ file.name }}</span>
          <button @click="deleteFile(index)" class="delete-file">×</button>
        </div>
      </div>
    </div>

    <!-- 输入框区域 -->
    <div class="message-input">
      <div class="input-container">
        <label class="file-upload">
          <input
            type="file"
            @change="handleFileUpload"
            :key="fileInputKey"
            multiple
            accept=".pdf,.doc,.docx,.txt"
          />
          <img src="@/assets/images/upfile.svg" alt="Upload" />
        </label>
        <textarea v-model="newMessage" placeholder="请输入..." @keyup.enter="sendMessage"></textarea>
        <el-button class="send-button" @click="sendMessage" :disabled="!newMessage.trim()">
          <img src="@/assets/images/send.svg" alt="Send" />
        </el-button>
      </div>
    </div>
  </div>
</template>



<script>
import axios from '@/utils/axios'; // 导入 Axios 实例

export default {
  name: 'ChatDetailView',
  data() {
    return {
      chatTitle: 'ChatGPT',
      chatId: '', 
      userId: '', 
      botuserId: '',
      messages: [], 
      newMessage: '',
      loading: false,
      logoSrc: '',
      brandName: '',
      showLogoutConfirm: false,
      uploadingFiles: [], // 当前正在上传的文件列表
      uploadProgress: {}, // 存储每个文件的上传进度
      uploadedFiles: [], // 存储已上传文件列表
      fileInputKey: Date.now(),
      fileContents: {}, // 用于存储所有文件拼接的文本内容
      fileContent:''
    };
  },
  mounted() {
    this.chatId = localStorage.getItem('chatId');
    this.setBranding();
    if(this.chatId !== '' && this.chatId != null) {
      this.loadChatHistory(); // 加载聊天记录
    }
  },
  methods: {
    async loadChatHistory() {
      try {
        const response = await axios.post('/session/chat/selectChatMessage?chatId=' + this.chatId);
        if (response.data) {
          this.userId = response.data.userId1;
          this.botuserId = response.data.userId2;
          this.chatId = response.data.chatId;
          this.messages = response.data.messages.map(message => ({
            id: Date.now(),  // 使用 Date.now() 作为唯一 ID 不够理想，最好使用消息中的唯一 ID
            sender: message.sender,
            content: message.content,
            time: new Date(message.timestamp).toLocaleTimeString(),
            type: message.sender === this.userId ? 'sent' : 'received',
          }));
          this.scrollToBottom();
        }
      } catch (error) {
        console.error('Error loading chat history:', error);
      }
    },
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      files.forEach(async (file) => {
        this.uploadingFiles.push(file);
        this.uploadProgress[file.name] = 0;

        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await axios.post('/file/parsing', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressEvent => {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              this.uploadProgress[file.name] = percentage;
              console.log(`上传进度: ${file.name} ${percentage}%`);
            },
          });

          // 获取解析的文本内容并拼接到 fileContent
          this.fileContents[file.name] = response.data.extractedText; // 使用对象存储每个文件的内容
          this.fileContent += response.data.extractedText + '\n'; // 将每个文件的文本内容追加，末尾加换行
          this.uploadedFiles.push(file); // 添加文件到已上传列表
          this.uploadingFiles = this.uploadingFiles.filter(f => f.name !== file.name); // 移除正在上传的文件
          delete this.uploadProgress[file.name]; // 清除进度条数据

        } catch (error) {
          console.error('上传失败:', error);
          this.uploadingFiles = this.uploadingFiles.filter(f => f.name !== file.name); // 移除上传失败的文件
          delete this.uploadProgress[file.name];
        }
      });
    },

    simulateUpload(file) {
      const uploadInterval = setInterval(() => {
        if (this.uploadProgress[file.name] >= 100) {
          clearInterval(uploadInterval);
          this.uploadedFiles.push(file); // 上传完成
          this.uploadingFiles = this.uploadingFiles.filter(f => f.name !== file.name);
          delete this.uploadProgress[file.name];
        } else {
          this.uploadProgress[file.name] += 10; // 增加进度
        }
      }, 100);
    },
    
    getStrokeDashoffset(progress) {
      const radius = 22;
      const circumference = 2 * Math.PI * radius;
      return circumference - (progress / 100) * circumference;
    },
    getFileIcon(fileName) {
      const extension = fileName.split('.').pop().toLowerCase();
      const icons = {
        pdf: require('@/assets/images/pdf-icon.svg'),
        doc: require('@/assets/images/doc-icon.svg'),
        docx: require('@/assets/images/doc-icon.svg'),
        txt: require('@/assets/images/txt-icon.svg'),
        // 可以继续添加其他类型的图标
      };
      return icons[extension] || require('@/assets/images/file-icon.svg'); // 默认图标
    },
    cancelUpload(fileName) {
      this.uploadingFiles = this.uploadingFiles.filter(file => file.name !== fileName);
      delete this.uploadProgress[fileName];
    },
    formatFileSize(size) {
      return (size / 1024).toFixed(2) + ' KB'; // 格式化文件大小为 KB
    },
    updateProgressRing() {
      const circle = document.querySelector('.progress-ring__circle');
      const radius = circle.r.baseVal.value;
      const circumference = 2 * Math.PI * radius;
      const offset = circumference - (this.uploadProgress / 100) * circumference;
      circle.style.strokeDashoffset = offset;
    },

    deleteFile(index) {
      const fileToDelete = this.uploadedFiles[index];
      
      // 从 fileContents 中移除对应的内容
      delete this.fileContents[fileToDelete.name];
      
      // 更新 fileContent，重新拼接剩余文件的内容
      this.fileContent = Object.values(this.fileContents).join('\n');
      
      this.uploadedFiles.splice(index, 1);
      this.fileInputKey = Date.now(); // 更新 key 以确保可以重复上传相同文件
    },
    toggleLogoutConfirm() {
      this.showLogoutConfirm = !this.showLogoutConfirm;
    },
    logout() {
      const loginSource = localStorage.getItem('loginSource');
      this.$router.push(`/${loginSource}`); // 将 loginSource 作为路由地址跳转

      // 清除所有缓存数据
      localStorage.clear();

      // 如果需要立即刷新页面或跳转到首页，可以添加如下行：
      // window.location.reload(); // 可选：刷新页面
    },
    setBranding() {
      if(localStorage.getItem('loginSource') == 'yijieLogin'){
        localStorage.setItem('nameVerification','false');
      }
      const logoUrl = localStorage.getItem('logoUrl');
      this.logoSrc = logoUrl;
      this.brandName = localStorage.getItem('brandName');
      this.userId = localStorage.getItem('userId');
      this.botuserId = localStorage.getItem('botId');
      // 在页面加载时生成一个欢迎消息
      this.messages.push({
        id: Date.now(),
        sender: localStorage.getItem('botId'),
        content: localStorage.getItem('honorific'),
        timestamp: new Date().toLocaleTimeString(),
        type: 'received',
      });
    },
    newChat(){
      localStorage.setItem('chatId','')
      window.location.reload();//新聊天
    },
    goHistory() {
      localStorage.setItem('chatId', this.chatId);
      this.$router.push('/list'); // 假设 '/' 是主页路由
    },
    goBack() {
      this.$router.go(-1);
    },
    formatContent(content) {
      return content.replace(/\n/g, '<br>');
    },
    async sendMessage() {
      if (this.newMessage.trim() !== '') {
        const chatId = this.chatId;
        const sender = this.userId;
        const time = new Date().toISOString();

        const userMessage = {
          id: Date.now(),
          sender: sender,
          content: this.newMessage,
          timestamp: time,
          type: 'sent',
        };

        this.messages.push({
          ...userMessage,
          time: new Date(time).toLocaleTimeString(),
        });

        this.scrollToBottom();
        const userInput = this.newMessage;
        this.newMessage = ''; // 清空输入框

        const loadingMessage = {
          id: Date.now() + 1,
          text: '',
          time: new Date().toLocaleTimeString(),
          type: 'received',
          loading: true,
        };

        this.messages.push(loadingMessage);
        this.loading = true;

        this.scrollToBottom();

        try {
          const lastMessages = this.messages
            .slice(-4)
            .filter(message => message.content && message.content.trim() !== '')
            .map(message => ({
              sender: message.sender,
              content: message.content,
              timestamp: message.timestamp,
            }));

            // const lastMessages = this.messages
            // .filter(message => message.content && message.content.trim() !== '')
            // .map(message => ({
            //   sender: message.sender,
            //   content: message.content,
            //   timestamp: message.timestamp,
            // }));

          let botMessage;
          if(localStorage.getItem('nameVerification')=='false'){
              const response = await axios.post('/api/chatgpt/askName', { 
              input: userInput,
              history: lastMessages,
              type: localStorage.getItem('botType') // 新增的type字段，假设此处为'legal'
            });
            let quest = '';
            console.log(response.data.choices[0].message.content);
            if(response.data.choices[0].message.content == 'true'){
              quest = '很高兴能够帮你解答法律问题，可以告诉我您的需求吗？'
              localStorage.setItem('nameVerification','true')
            }else{
              quest = '抱歉，我需要了解您的姓名后才可继续为您服务！'
            }
            botMessage = {
              id: Date.now() + 2,
              sender: this.botuserId,
              content: quest,
              timestamp: new Date().toISOString(),
              type: 'received',
              loading: false,
            };
          }else{
              //生产环境地址http://hy.zenpass.cn/api/v1/chat-messages
              //测试环境地址http://127.0.0.1:5001/v1/chat-messages
              // 假设 topToke 和 btmToke 是从缓存中获取的值
              const topToke = localStorage.getItem('topToke'); // 从缓存获取 topToke
              console.log(topToke);
              const btmToke = localStorage.getItem('btmToke'); // 从缓存获取 btmToke

              // 调用接口获取响应
              const response = await axios.post(
                'http://hy.zenpass.cn/api/v1/chat-messages',
                {
                  query: userInput + this.fileContent,
                  inputs: {
                    text: "Hello, how can I help you?"
                  },
                  user: "unique-user-id"
                },
                {
                  headers: {
                    'Authorization': localStorage.getItem('apiKey'),
                    'Content-Type': 'application/json'
                  }
                }
              );

              // 对 response.data.answer 进行处理
              let modifiedAnswer = response.data.answer;

              // 如果 topToke 不为空，添加到回答开头并换行
              if (topToke) {
                modifiedAnswer = `${topToke}\n\n${modifiedAnswer}`;
              }

              // 如果 btmToke 不为空，添加到回答末尾并换行
              if (btmToke) {
                modifiedAnswer = `${modifiedAnswer}\n\n${btmToke}`;
              }

              // 创建 botMessage 对象
              botMessage = {
                id: Date.now() + 2,
                sender: this.botuserId,
                content: modifiedAnswer, // 使用修改后的回答
                timestamp: new Date().toISOString(),
                type: 'received',
                loading: false,
              };
          }

          this.messages.pop();
          this.messages.push({
            ...botMessage,
            time: new Date(botMessage.timestamp).toLocaleTimeString(),
          });


          this.scrollToBottom();

          const fullResponse = await axios.post(
            '/session/chat/update', 
            {
              chatId: chatId,
              userId1: this.userId,
              userId2: this.botuserId,
              message: {
                sender: userMessage.sender,
                content: userMessage.content,
                timestamp: userMessage.timestamp,
              }
            }
          );
          const newChatId = fullResponse.data.split("chatId:")[1];
          this.chatId = newChatId;

          await axios.post(
            '/session/chat/update', 
            {
              chatId: chatId,
              userId1: this.userId,
              userId2: this.botuserId,
              message: {
                sender: botMessage.sender,
                content: botMessage.content,
                timestamp: botMessage.timestamp,
              }
            }
          );

        } catch (error) {
          console.error('Error:', error);
          this.messages.pop();
        } finally {
          this.loading = false;
        }
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const anchor = this.$refs.scrollAnchor;
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  },
  watch: {
    uploadProgress() {
      this.updateProgressRing();
    }
  }
};
</script>


<style scoped>
/* 设置html和body的基础样式，确保宽度和高度占满全屏 */
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
}

/* 设置全局的盒模型属性 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* 整个页面容器的样式 */
.chat-detail-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw; /* 确保宽度占满全屏 */
  overflow: hidden;
  background-color: #f5f5f5;
}

/* 修改后的页面头部样式 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.brand-name {
  font-size: 16px;
  color: #333;
}

.header-right {
  display: flex;
  align-items: center;
}

.home-icon, .settings-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.divider {
  width: 1px;
  height: 32px;
  background-color: #ccc;
  margin: 0 10px;
}

/* 消息列表的样式 */
.messages-list {
  flex: 1;
  padding: 10px;
  background-color: #f5f5f5;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  transition: height 0.3s ease;
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message.received {
  justify-content: flex-start;
}

.message.sent {
  flex-direction: row-reverse;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

.message-content {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message.sent .message-content {
  align-items: flex-end;
}

.message-bubble {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.message.received .message-bubble {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.message.sent .message-bubble {
  background-color: #9eea6a;
  border: 1px solid #9eea6a;
}

.message-bubble::before {
  content: '';
  position: absolute;
  top: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
}

.message.received .message-bubble::before {
  left: -12px;
  border-right-color: #ffffff;
}

.message.sent .message-bubble::before {
  right: -12px;
  border-left-color: #9eea6a;
}

.message-time {
  font-size: 12px;
  color: #888;
  text-align: right;
}

.message-input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 5px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-container textarea {
  flex: 1;
  border: none;
  outline: none;
  resize: none;
  padding: 10px;
  font-size: 16px;
  background-color: transparent;
  border-radius: 20px;
  height: 40px;
}

.send-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.send-button img {
  width: 24px;
  height: 24px;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #999;
  border-radius: 50%;
  animation: loading 1.2s infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.4s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.8s;
}

.logout-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-box {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirm-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
}

.confirm-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-buttons button:first-child {
  background-color: #d9534f;
  color: white;
}

.confirm-buttons button:last-child {
  background-color: #5bc0de;
  color: white;
}

.file-upload {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.file-upload input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-upload img {
  width: 24px;
  height: 24px;
}

/* 文件上传显示区域 */
.file-upload-display {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  gap: 10px;
  white-space: nowrap;
}

/* 文件容器样式，支持横向滚动 */
.uploaded-files-container {
  display: flex;
  gap: 10px;
}

.file-upload-progress,
.uploaded-file {
  position: relative;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 100px;
  max-width: 120px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.3s ease;
}

/* 半透明遮罩层 */
.file-upload-progress.uploading {
  opacity: 0.5;
}

/* 文件内居中显示的进度圆形条 */
.circular-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  z-index: 2;
}
.progress-ring__circle {
  fill: transparent;
  stroke: #76c7c0;
  stroke-width: 4;
  stroke-dasharray: 138;
  stroke-dashoffset: 138;
  transition: stroke-dashoffset 0.3s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

/* 居中文本 */
.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #333;
  z-index: 3;
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1; /* 确保遮罩在文件内容上层 */
}

/* 删除按钮在右上角显示 */
.delete-file {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 12px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.delete-file:hover {
  background-color: #ff4b4b;
}

/* 文件名称显示 */
.file-name {
  font-size: 12px;
  text-align: center;
  white-space: normal;
  word-break: break-word;
  overflow: hidden;
  margin-top: 4px;
}

.uploaded-file-progress {
  position: relative;
  width: 100px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.file-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px; /* 添加间距 */
}


@keyframes loading {
  0% {
    transform: scale(1);
    background-color: #999;
  }
  50% {
    transform: scale(1.5);
    background-color: #333;
  }
  100% {
    transform: scale(1);
    background-color: #999;
  }
}
</style>

